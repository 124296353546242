import { AdminUser } from './admin-user'
import { MappableModel } from './mappable-model'

export class UserSearch extends MappableModel implements AdminUser {
  public id: number
  public user_name: string
  public first_name: string
  public last_name: string
  public email: string
  public created_on: string
  public updated_on: string
  public last_sign_in_at: string
  public authentication_token: string
  public current_sign_out_at: string
  public last_sign_out_at: string
  public facebook_token: string
  public uuid: string
  public activated: boolean
  public allow_third_party_mailing: boolean
  public third_north_access: boolean
  public permission_names: string[]
  public contact_emails: string[]
  public site_ids: number[]
  public primary_profile_image: string
  public validated_mobile_phone_number: string

  public get name(): string {
    return `${this.first_name} ${this.last_name}`
  }

  public get primary_email(): string {
    return this.email || (this.contact_emails && this.contact_emails[0])
  }
}
