/* eslint-disable */
import { Injectable } from '@angular/core'

import { environment } from '../../environments/environment'

import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class NginApiService extends ApiService {
  public baseUrl = environment.cmsUrl
  public resultAttr = 'result'
  public defaultHeaders = { Accept: 'application/vnd.ngin-api.v2, application/json' }
}
