import { ParamMap } from '@angular/router'

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function isParamMap(arg: any): arg is ParamMap {
 return arg && arg.keys !== undefined
}

class QSParamMap implements ParamMap {
  private params: any

  constructor(params: any) {
    this.params = params
  }

  public has(name: string): boolean {
    return this.params[name] !== undefined
  }

  public get(name: string): string | null {
    let val = this.params[name]
    if (Array.isArray(val)) {
      val = val[0]
    }
    return val ? val.toString() : val
  }

  public getAll(name: string): string[] {
    let val = this.params[name]
    if (!val) {
      val = []
    } else if (!Array.isArray(val)) {
      val = [val]
    }
    return val.map(el => el.toString())
  }

  get keys(): string[] {
    return this.params ? Object.keys(this.params) : []
  }
}

export class QueryString {
  public static build(params: ParamMap | any): string {
    const map   = isParamMap(params) ? params : new QSParamMap(params)
    const pairs = []
    map.keys.forEach((key) => {
      map.getAll(key).forEach((value) => {
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
      })
    })
    return pairs.join('&')
  }

  public static paramMap(params: any): ParamMap {
    return new QSParamMap(params)
  }
}
