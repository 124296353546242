import { Routes } from '@angular/router'
import { AdminGuardService, AffiliationGuardService } from './services'

export const routes: Routes = [
  { path: '', redirectTo: 'affiliations', pathMatch: 'full' },
  {
    path: 'affiliations',
    canActivate: [AffiliationGuardService],
    loadChildren: () => import('./affiliations/affiliations.module').then(m => m.AffiliationsModule),
  },
  {
    path: 'organizations',
    canActivate: [AdminGuardService],
    loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule),
  },
  {
    path: 'personas',
    canActivate: [AdminGuardService],
    loadChildren: () => import('./personas/personas.module').then(m => m.PersonasModule),
  },
  {
    path: 'users',
    canActivate: [AdminGuardService],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'admins',
    canActivate: [AdminGuardService],
    loadChildren: () => import('./admins/admins.module').then(m => m.AdminsModule),
  },
  {
    path: 'review-duplicates',
    canActivate: [AdminGuardService],
    loadChildren: () => import('./review-duplicates/review-duplicates.module').then(m => m.ReviewDuplicatesModule)
  },
]
