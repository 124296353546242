import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import * as _ from 'lodash'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeToastModule } from 'se-fe-toast'
import { SeFeLeftNavModule } from 'se-fe-left-nav'

import { AppComponent } from './app.component'
import { ElementService } from './element.service'
import { routes } from './routes'
import { EncodeUrlParamsSafelyInterceptor } from './services/encode-url-params-safely.interceptor'
import { APP_BASE_HREF } from '@angular/common'

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  providers: [
    {
      provide: Window,
      useValue: window
    },
    { provide: APP_BASE_HREF, useValue: '' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeUrlParamsSafelyInterceptor,
      multi: true,
    },
    ElementService
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {}),
    SeFeAvatarModule,
    SeFeToastModule,
    SeFeLeftNavModule,
    SeFeToastModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
