import { Injectable } from '@angular/core'
import { UserServiceResources as user } from 'se-resource-types'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class RoleAssignmentsService {

  constructor(
    private api: ApiService
  ) { }

  public deleteRoleAssignment(userId: number | string, ra: user.RoleAssignment): Observable<any> {
    return this.api.delete(`users/${userId}/role_assignments/${ra.id}`)
  }

  public getRoleAssignments(userId: number | string): Observable<user.RoleAssignment[]> {
    return this.api.get(`users/${userId}/role_assignments?collapsed=1`)
      .pipe(map(response => response.result))
  }
}
