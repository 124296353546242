<se-fe-layout #layout>
  <se-bar seFeLayoutGlobalNav role="banner"></se-bar>
  <se-fe-left-nav
    *ngIf="showLeftNav"
    seFeLayoutLeftNav
    [seFeDataNavTree]="navItems"
    [seFeDataTheme]="dark"
    seFeDataContextTitle="BOSS"
    (seFeNavigate)="handleNavigate($event)">
    <div seFeLeftNavVisual class="boss-avatar">
      <se-fe-avatar
        seFeDataType="org"
        [seFeDataImgUrl]="logo"
        seFeDataIdentity="BOSS"
        seFeDataSize="700">
      </se-fe-avatar>
    </div>
  </se-fe-left-nav>
  <div seFeLayoutPageContent>
    <se-fe-toast></se-fe-toast>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</se-fe-layout>
