import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'

import { User } from '../models'

import { ApiService } from './api.service'
import { Mapper } from './mapper.service'
import { Store } from './store'

@Injectable({
  providedIn: 'root'
})
export class UserService extends Store<User> {

  constructor(private api: ApiService) {
    super(null)
  }

  public findCurrent(): void {
    this.getUser().pipe(first())
      .subscribe(
        (data: any) => {
          const user = Mapper.as<User>((data.result && data.result.user) || { }, User)
          this.setCurrent(user)
        },
        error => {
          this.setCurrent(new User({ }))
        }
      )
  }

  public getUser(): Observable<User> {
    return this.api.get('oauth/me')
  }
}
