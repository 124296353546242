import { Injectable } from '@angular/core'

import { OAuthState, QueryString, User } from '../models'
import { environment } from '../../environments/environment'

import { UserService } from './user.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user: User
  private userAuthenticatedResult: Promise<User>

  constructor(
    private userService: UserService
  ) {
    this.userAuthenticatedResult = new Promise<User>((resolve, reject) => {
      this.userService.current.subscribe((user: User) => {
        this.user = user
        if (user) {
          resolve(user)
        }
      })
    })
  }

  public async isAuthenticated(): Promise<boolean> {
    if (!this.user) {
      await this.userAuthenticatedResult
    }
    return this.user.isAuthenticated()
  }

  public async isAdmin(): Promise<boolean> {
    if (!this.user) {
      await this.userAuthenticatedResult
    }
    return this.user.hasRole('platform_admin') || this.user.hasRole('third_north')
  }

  public async isAffiliationAdmin(): Promise<boolean> {
    if (!this.user) {
      await this.userAuthenticatedResult
    }
    return this.user.hasRole('platform_admin') || this.user.hasRole('platform_affiliation_admin')
  }

  public async isSupportAdmin(): Promise<boolean> {
    if (!this.user) {
      await this.userAuthenticatedResult
    }
    return this.user.hasRole('support_admin')
  }

  public initiateLogin(returnURL: string): void {
    const state = new OAuthState({ returnTo: returnURL })
    state.save()
    window.location.href = this.redirectUrl(state.state)
  }

  private redirectUrl(state: string): string {
    const queryParams: any = {
      client_id: environment.clientId,
      redirect_uri: window.location.origin + '/oauth/callback',
      response_type: 'none',
      state
    }
    return environment.userUrl + '/oauth/authorize?' + QueryString.build(queryParams)
  }
}
