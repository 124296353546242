import { ElementRef, Injectable } from '@angular/core'

@Injectable()
export class ElementService {

  constructor() {

  }

  /**
   * Reads the attribute value from the provided element.
   * Normally this is handled by the `@Input` decorator. However, this
   * decorator does not work if the attribute is specified at run time instead of
   * build time. This method reads the attribute specified at run time.
   *
   * @param el - The element to read from.
   * @param attributeName - The name of the attribute to read.
   * @returns - The attribute value.
   */
  public getAttribute(el: ElementRef, attributeName: string): string {
    const value = el.nativeElement.getAttribute(attributeName)
    if (!value) {
      throw new Error(`${attributeName} is a required attribute ${el.nativeElement.tagName}`)
    }

    return value
  }
}
