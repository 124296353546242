import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild } from '@angular/router'
import { Observable } from 'rxjs'

import { AuthService } from './auth.service'
import { AuthGuardService } from './auth-guard.service'

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate, CanActivateChild {
  constructor(
    public authService: AuthService,
    public authGuard: AuthGuardService) { }

  public canActivate(): Observable<boolean> {
    const returnURL = 'https://www.sportsengine.com'
    return new Observable<boolean>((observer) => {
      this.authService.isAdmin().then((result) => {
        console.log('isAdmin', result)
        observer.next(result)
        observer.complete()
        if (!result) {
          window.location.href = returnURL
        }
      })
    })
  }

  public canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}
