import { Injectable } from '@angular/core'
import {
  CanActivate, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router'
import { Observable } from 'rxjs'

import { QueryString } from '../models'

import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(public authService: AuthService) { }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const returnURL = this.makeURL(route)

    return new Observable<boolean>((observer) => {
      this.authService.isAuthenticated().then((authenticated) => {
        observer.next(authenticated)
        observer.complete()
        if (!authenticated) {
          this.authService.initiateLogin(returnURL)
        }
      })
    })
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route)
  }

  public makeURL(route: ActivatedRouteSnapshot): string {
    let path = '/' + route.url.map((segment) => segment.path).join('/')
    const qs = QueryString.build(route.queryParamMap)
    if (route.children && route.children.length) {
      path += '/' + route.children.map(child => child.url.map(seg => seg.path).join('/'))
    }

    return qs.length === 0 ? path : path + '?' + qs
  }
}
