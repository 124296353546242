import * as _ from 'lodash'

export class MappableModel {
  public metadata: any = { }
  public errors: any

  constructor(data: any) {
    this.update(data)
  }

  public update(data: any) {
    _.assign(this, data)
    this.deserialize()
  }

  public deserialize(): void { }
}
