import { ProfileImage } from './profile-image'
import { UserEmailAddress } from './user-email-address'
import { MappableModel } from './mappable-model'

interface RoleAssignment {
  resource_type: string
  resource_id: string
  role: string
}

export class User extends MappableModel {
  public email_addresses: UserEmailAddress[]
  public first_name: string
  public id: number
  public last_name: string
  public profile_images: ProfileImage[]
  public uri: string
  public user_name: string
  public uuid: string
  public role_assignments: RoleAssignment[]

  public get fullName(): string {
    const names: string[] = []
    if (this.first_name) names.push(this.first_name)
    if (this.last_name) names.push(this.last_name)
    return names.join(' ')
  }

  public isAuthenticated(): boolean {
    return !!this.id
  }

  public hasRole(role: string, type?: string, id?: string): boolean {
    return !!(this.role_assignments || []).find(ra => (ra.role === role) &&
        (!type || type === ra.resource_type) &&
        (!id || id === ra.resource_id))
  }
}
