export class OAuthState {
  public static readonly KEY = 'OAuthState'

  public returnTo: string
  private _state: string

  constructor(data?: any) {
    if (data.returnTo) this.returnTo = data.returnTo as string
    if (data.state)    this._state   = data.state as string
  }

  public get state() {
    if (!this._state) this._state = this.generateRandomStateValue()
    return this._state
  }

  public set state(v: string) {
    this._state = v
  }

  public static load(): OAuthState {
    const item = window.localStorage.getItem(OAuthState.KEY)
    let   data = { }

    if (item) data = JSON.parse(item)
    window.localStorage.removeItem(OAuthState.KEY)
    return new OAuthState(data)
  }

  public save(): void {
    const dataStr = JSON.stringify({ state: this.state, returnTo: this.returnTo })
    window.localStorage.setItem(OAuthState.KEY, dataStr)
  }

  private generateRandomStateValue(): string {
    return Math.random().toString(36).substring(2) +
      Math.random().toString(36).substring(2)
  }
}
