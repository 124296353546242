import { MappableModel } from './mappable-model'

export class OrphanInvite extends MappableModel {
  public id: number
  public user_id: number
  public email_address_id: number
  public persona: any
  public email_address: string
  public inviter_name: string
}
