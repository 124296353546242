import { MappableModel } from './mappable-model'

export class SiteMembership extends MappableModel {
  public id: number
  public status: string
  public persona_id: number
  public site_id: number
  public site_name: string
  public site_url: string
  public site_logo_graphic: string
  public site_link_color: string
  public site_hover_color: string
  public site_accent_color: string
  public site_background_color: string
  public site_highlight_color: string
  public organization_id: number
  public organization_name: string
}
