import { MappableModel } from './mappable-model'

interface AdminUserProfileImage {
  image_type: 'original' | 'crop' | 'crop_icon'
  url: string
}

interface AdminUserRoleAssignment {
  id: number
  role: string
  org_id: number
  resource_type: string
  resource_id: string
  principal_type: string
  principal_id: number
  created_at: string
  updated_at: string
  role_type: 'SimpleRole' | 'CompositeRole'
  role_id: number
  inheritable: boolean
  resource_system: string
  principal_system: string
}

interface AdminUserGroup {
  created_at: string
  id: number
  name: string
  owner_id: string | number
  owner_type: string
  protected: boolean
  updated_at: string
}

export interface AdminEmailAddress {
  id: number
  address: string
  is_primary: boolean
  is_validated: boolean
}

export interface AdminUser {
  activated: boolean
  allow_third_party_mailing: boolean
  api_token?: string
  authentication_token: string
  created_on: string
  current_sign_out_at: string
  email: string
  facebook_token: string
  first_name: string
  id: number
  last_name: string
  last_sign_in_at: string
  last_sign_out_at: string
  must_change_password?: boolean
  name: string
  password_conversion_needed?: boolean
  password_custom_validator?: any
  primary_email: string
  profile_image?: AdminUserProfileImage[]
  remember_token_expires_at?: string
  role_assignments?: AdminUserRoleAssignment[]
  third_north_access: boolean
  updated_on: string
  user_name: string
  user_preferences?: any[]
  uuid: string
  validated_mobile_phone_number: string
  primary_profile_image: string
  email_addresses?: AdminEmailAddress[]
  pending_email_addresses?: AdminEmailAddress[]
  isGuarded?: boolean
  personasAndGuardedPersonas?: ApiAdminUserPersona[]
}

export class ApiAdminUserPersona extends MappableModel {
  public country_of_birth: string
  public created_at: string
  public date_of_birth: string
  public disability: string
  public first_name: string
  public gender: string
  public graduation_year: string
  public id: number
  public last_name: string
  public middle_name: string
  public name: string
  public nickanme: string
  public owner_user_id: number
  public persona_listeners?: ApiAdminUserListener[]
  public persona_type_id: number
  public played_outside_us: boolean
  public profile_image_id: number
  public profile_images: AdminUserProfileImage[]
  public sport_ngin_id: string
  public suffix: string
  public updated_at: string
  public uuid: string
  public isGuarded: boolean

  public profileImage(imgType: 'original' | 'crop' | 'crop_icon' = 'crop'): string {
    return (this.profile_images || []).find(img => img.image_type === imgType)?.url
  }

  public get primary_profile_image(): string {
    return this.profileImage()
  }

  public get isSelf(): boolean {
    return this.persona_type_id === 1
  }

  public get isOther(): boolean {
    return this.persona_type_id === 3
  }

  public get isTeam(): boolean {
    return this.persona_type_id === 4
  }

  public get isSmartGroup(): boolean {
    return this.persona_type_id === 5
  }

  public get isStaticGroup(): boolean {
    return this.persona_type_id === 6
  }

  public get isOrphan(): boolean {
    return this.persona_type_id === 7
  }
}

export class ApiAdminPersonaListener extends MappableModel {
  public id: number
  public persona_id: number
  public email_address_id: number
  public status: string
  public listener_type: string
  public email_address?: any
  public persona: ApiAdminUserPersona

  public deserialize(): void {
    if (this.persona) this.persona = new ApiAdminUserPersona(this.persona)
  }
}

export interface UserListenerDetails {
  id: number
  name: string
}
export class ApiAdminUserListener extends MappableModel {
  public id: number
  public persona_id: number
  public user_id: number
  public email_address_id: number
  public status: string
  public listener_type: string
  public user: UserListenerDetails
}
export class ApiAdminUser extends MappableModel implements AdminUser {
  public activated: boolean
  public allow_third_party_mailing: boolean
  public api_token?: string
  public authentication_token: string
  public created_on: string
  public current_sign_in_at: string
  public current_sign_out_at: string
  public email: string
  public facebook_token: string
  public first_name: string
  public id: number
  public last_name: string
  public last_sign_in_at: string
  public last_sign_out_at: string
  public must_change_password?: boolean
  public name: string
  public password_conversion_needed?: boolean
  public password_custom_validator?: any
  public personas?: ApiAdminUserPersona[]
  public persona_listeners?: ApiAdminPersonaListener[]
  public primary_email: string
  public profile_images?: AdminUserProfileImage[]
  public remember_token_expires_at?: string
  public role_assignments?: AdminUserRoleAssignment[]
  public third_north_access: boolean
  public updated_on: string
  public user_name: string
  public user_preferences?: any[]
  public uuid: string
  public validated_mobile_phone_number: string
  public email_addresses?: AdminEmailAddress[]
  public pending_email_addresses?: AdminEmailAddress[]
  private _personasAndGuardedPersonas?: ApiAdminUserPersona[]
  private _guarded_personas?: ApiAdminUserPersona[]

  public deserialize(): void {
    if (this.personas) this.personas = this.personas.map(p => new ApiAdminUserPersona(p))
  }

  public profileImage(imgType: 'original' | 'crop' | 'crop_icon' = 'crop'): string {
    return (this.profile_images || []).find(img => img.image_type === imgType)?.url
  }

  public get primary_profile_image(): string {
    return this.profileImage()
  }

  public get guarded_personas(): ApiAdminUserPersona[] {
    if (this._guarded_personas === undefined) {
      this._guarded_personas = []
      if (this.persona_listeners) {
        this._guarded_personas = this.persona_listeners.map(p => new ApiAdminUserPersona({ ...p.persona, isGuarded: true }))
      }
    }
    return this._guarded_personas
  }

  public get personasAndGuardedPersonas(): ApiAdminUserPersona[] {
    if (this._personasAndGuardedPersonas === undefined) {
      this._personasAndGuardedPersonas = [...this.personas, ...this.guarded_personas]
    }
    return this._personasAndGuardedPersonas
  }

  public removePersona(persona: ApiAdminUserPersona): void {
    this._personasAndGuardedPersonas = undefined
    this.personas = this.personas.filter(p => p.id !== persona.id)
  }

  public removeEmail(email: AdminEmailAddress): void {
    this.email_addresses = this.email_addresses.filter(e => e.id !== email.id)
    this.pending_email_addresses = this.pending_email_addresses.filter(e => e.id !== email.id)
  }
}
