import { MappableModel } from './mappable-model'

export class PersonaListener extends MappableModel {
  public id: number
  public persona_id: number
  public user_id: number
  public email_address_id: number
  public status: string
  public listener_type: string
  public persona_name: string
  public owner_persona_name: string
  public persona: any
}
