import { MappableModel } from './mappable-model'
import { ProfileImage } from './profile-image'
import { User } from './user'

export class Persona extends MappableModel {
  public id: number
  public first_name: string
  public last_name: string
  public date_of_birth: string
  public type: string
  public email_addresses: PersonaEmailAddress[]
  public profile_images: ProfileImage[]
  public user: User

  public get emailList(): string {
    return this.email_addresses.map(email => email.address).join(', ')
  }

  public profileImage(imgType: 'original' | 'crop' | 'crop_icon' = 'crop'): string {
    return (this.profile_images || []).find(img => img.image_type === imgType)?.url
  }

  public get name(): string {
    const names: string[] = []
    if (this.first_name) names.push(this.first_name)
    if (this.last_name) names.push(this.last_name)
    return names.join(' ')
  }

  public get userFullName(): string {
    const names: string[] = []
    if (this.user?.first_name) names.push(this.user?.first_name)
    if (this.user?.last_name) names.push(this.user?.last_name)
    return names.join(' ')
  }

  public get isSelf(): boolean {
    return this.type === 'self'
  }

  public get isOther(): boolean {
    return this.type === 'other'
  }

  public get isTeam(): boolean {
    return this.type === 'team'
  }

  public get isSmartGroup(): boolean {
    return this.type === 'smart_group'
  }

  public get isStaticGroup(): boolean {
    return this.type === 'static_group'
  }

  public get isOrphan(): boolean {
    return this.type === 'orphan'
  }
}

export interface PersonaEmailAddress {
  id: number
  address: string
}
