import { Injectable } from '@angular/core'

import { environment } from '../../environments/environment'

import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends ApiService {
  public baseUrl = environment.userUrl
  public resultAttr = 'result'
}
