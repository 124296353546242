import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivationEnd, Router } from '@angular/router'
import {
  SeFeLayoutComponent,
  SeFeLeftNavEvent,
  SeFeLeftNavRouterService,
} from 'se-fe-left-nav'

import { environment } from '../environments/environment'

import { Title } from '@angular/platform-browser'
import { User } from './models'
import { UserService } from './services'
import { AuthService } from './services/auth.service'

@Component({
  selector: 'se-app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  public logo = `${environment.uiThemes}/se-logos/se-icon.svg`
  public user: User
  public env = environment
  public weekAgo: string
  public currentNav: string
  public currentSubNav: string
  public currentNavMenu: string
  public navItems: any[] = []
  public showLeftNav = false
  public selectedItemPath = ''

  @ViewChild('layout') private layout: SeFeLayoutComponent

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private routerService: SeFeLeftNavRouterService,
    private window: Window,
    private titleService: Title,

    // @Inject(DOCUMENT) private doc: any
  ) {
    const dt = new Date((new Date() as any) - 60 * 60 * 24 * 7 * 1000)
    const yr = dt.getFullYear()
    const m = dt.getMonth() + 1
    const d = dt.getDay()
    const mm = m < 10 ? `0${m}` : `${m}`
    const dd = d < 10 ? `0${d}` : `${d}`
    this.weekAgo = `${yr}-${mm}-${dd}`
  }

  public ngOnInit(): void {
    this.loadSeBar()
    this.initRoute()
    this.initUser()
  }

  public initUser(): void {
    this.userService.current.subscribe((user: User) => {
      if (!user || !user.isAuthenticated()) return
      this.user = user
      this.buildNavItems()
    })
    this.userService.findCurrent()
  }

  public handleNavigate(e: SeFeLeftNavEvent): void {
    this.routerService.handleNavigate(e)
    this.layout.closeLeftNav()
  }

  private setLeftNavSelected(data: any) {
    const match = data?.leftNavItem || data?.title

    this.navItems.forEach((nav) => (nav.isSelected = match === nav.gaLabel))
    document.dispatchEvent(new CustomEvent('se-left-nav.triggerTreeChanges'))
  }

  private showHideNav(val: ActivationEnd): void {
    const routeConfig = val.snapshot.routeConfig
    const hideParam: boolean =
      val.snapshot.queryParams?.hide_nav === '1' || false
    this.showLeftNav = !routeConfig.data?.hideLeftNav && !hideParam

    if (hideParam) {
      this.window.document
        .querySelector('se-bar')
        .setAttribute('style', 'display: none')
    } else {
      this.window.document.querySelector('se-bar').removeAttribute('style')
    }
  }

  private initRoute(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof ActivationEnd && !val.snapshot.children.length) {
        const routeConfig = val.snapshot.routeConfig

        this.showHideNav(val)

        if (routeConfig.data?.title) {
          this.titleService.setTitle(routeConfig.data.title)
        } else {
          this.titleService.setTitle('Boss UI')
        }
        this.setLeftNavSelected(routeConfig.data)
      }
    })
  }

  private async buildNavItems(): Promise<void> {
    const isSupportAdmin = await this.authService.isSupportAdmin()
    const isPlatformAdmin = await this.authService.isAffiliationAdmin()
    this.navItems = [
      {
        label: 'Organizations',
        secondaryItems: [
          { label: 'Accounts', attributes: { href: `${environment.bossUrl}admin/billing_accounts` } },
          { label: 'Organizations', attributes: { href: `${environment.bossUrl}admin/organizations` } },
          { label: 'Products', attributes: { href: `${environment.bossUrl}admin/products` } },
          { label: 'Features', attributes: { href: `${environment.bossUrl}admin/features` } },
          { label: 'Usage', attributes: { href: `${environment.bossUrl}admin/usage?created_after=${this.weekAgo}` } },
          { label: 'Affiliations', attributes: { href: '/affiliations' } },
        ]
      },
      {
        label: 'Users',
        secondaryItems: [
          { label: 'Users', attributes: { href: '/users/search' } },
          { label: 'Groups', attributes: { href: `${environment.userUrl}admin/groups` } },
          { label: 'Oauth2 Clients', attributes: { href: `${environment.userUrl}admin/clients` } },
          { label: 'Me', attributes: { href: `${environment.userUrl}admin/users/${this.user.id}` } },
        ]
      }
    ]
    this.addPersonaSearchIfAdmin(isSupportAdmin)
    this.navItems = this.navItems.concat([
      { label: 'Multi-Household Merge', attributes: { href: '/users/merge' } },
      { label: 'Admins', attributes: { href: '/admins' } }
    ])
    this.addMembershipDashboardIfAdmin(isSupportAdmin)
    this.addReviewDuplicatesIfAdmin(isPlatformAdmin)

    this.showLeftNav = true
    this.routerService.setNavTree(this.navItems)
  }

  private loadSeBar(): void {
    const seBarScript = document.createElement('script')
    seBarScript.src = environment.seBarJs
    document.head.appendChild(seBarScript)
  }

  private async addPersonaSearchIfAdmin(isSupportAdmin: boolean): Promise<void> {
    if (isSupportAdmin) {
      this.navItems.push({ label: 'Personas', attributes: { href: '/personas/search' } })
    }
  }

  private async addReviewDuplicatesIfAdmin(isPlatformAdmin: boolean): Promise<void> {
    if (isPlatformAdmin) {
      this.navItems.push({ label: 'Review Duplicates', attributes: { href: '/review-duplicates' } })
    }
  }

  private async addMembershipDashboardIfAdmin(isSupportAdmin: boolean): Promise<void> {
    if (isSupportAdmin) {
      this.navItems.push({ label: 'Eligibility Support Dashboard', attributes: { href: `${environment.memDashUrl}` } })
    }
  }
}
