import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild } from '@angular/router'
import { Observable } from 'rxjs'

import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AffiliationGuardService implements CanActivate, CanActivateChild {
  constructor(
    public authService: AuthService) { }

  public canActivate(): Observable<boolean> {
    const returnURL = 'https://www.sportsengine.com'
    return new Observable<boolean>((observer) => {
      this.authService.isAffiliationAdmin().then((result) => {
        observer.next(result)
        observer.complete()
        if (!result) {
          window.location.href = returnURL
        }
      })
    })
  }

  public canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}
