/* eslint-disable */
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { environment } from '../../environments/environment'

import { Mapper } from './mapper.service'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public baseUrl: string
  public resultAttr: string = 'result'
  public cache: any = {}
  public cacheEnabled = true
  public defaultHeaders: any = {}

  constructor(private http: HttpClient) { }

  public get(path: string, options?: any): Observable<any> {
    const cacheKey = btoa(JSON.stringify({ path, options }))
    if (this.cacheEnabled && this.cache.hasOwnProperty(cacheKey)) {
      return of(this.cache[cacheKey])
    }
    return this.http.get(this.uri(path), this.getOptions(options))
      .pipe(map(result => {
        if (this.cacheEnabled) this.cache[cacheKey] = result
        return result
      }))
  }

  public post(path: string, body: any, options?: any): Observable<any> {
    return this.http.post(this.uri(path), body, this.getOptions(options))
  }

  public put(path: string, body: any, options?: any): Observable<any> {
    return this.http.put(this.uri(path), body, this.getOptions(options))
  }

  public delete(path: string, options?: any): Observable<any> {
    return this.http.delete(this.uri(path), this.getOptions(options))
  }

  public findCurrent<T>(url: string, subject: BehaviorSubject<T>, klass: { new(...args: any[]): T }): void {
    this.get(url)
      .subscribe(
        (data: any) => {
          const model = Mapper.as<T>(data.result, klass)
          subject.next(model)
        }
      )
  }

  public find<T>(url: string, opt?: any): Observable<T> {
    return this.get(url, opt)
      .pipe(first())
      .pipe(map(data => {
        return this.getResult(data)
      }))
  }

  public create<T>(url: string, body: any, options?: any): Observable<T> {
    return this.post(url, body, options)
      .pipe(first())
      .pipe(map(data => {
        return this.getResult(data)
      }))
  }

  public findAs<T>(url: string, klass: { new(...args: any[]): T }): Observable<T> {
    return this.get(url)
      .pipe(first())
      .pipe(map(data => {
        const result = this.getResult(data)
        const r = Mapper.as<T>(result, klass)
        r['metadata'] = data.metadata
        return r
      }))
  }

  public findAllAs<T>(url: string, opt: any, klass: { new(...args: any[]): T }): Observable<T[]> {
    return this.get(url, opt)
      .pipe(first())
      .pipe(map(data => {
        const result = this.getResult(data)
        const r = Mapper.asArray<T>(result, klass)
        r['metadata'] = data.metadata
        return r
      }))
  }

  protected uri(path: string): string {
    if (path.substring(0, 6) === 'https:') return path
    return (this.baseUrl || environment.apiUrl) + encodeURI(path)
  }

  private getOptions(options?: any): any {
    options = options || {}
    options.withCredentials = true
    options.headers = options.headers || this.defaultHeaders
    options.headers.Accept = options.headers.Accept || 'application/json'
    return options
  }

  private getResult(data): any {
    const r = this.resultAttr ? data[this.resultAttr] : data
    r['metadata'] = data.metadata
    return r
  }
}
